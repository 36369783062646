import React, { useRef, useEffect } from "react";
import THREE from "../utils/Three.js";

const ThreeScene = ({ geometry, minSize = 0.5, maxSize = 1 }) => {
  const mountRef = useRef(null);
  const meshRef = useRef(null);

  useEffect(() => {
    const currentMount = mountRef.current;

    // Scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    // Orthographic Camera
    let aspect = currentMount.clientWidth / currentMount.clientHeight;
    let d = 2000 / currentMount.clientWidth;

    const camera = new THREE.OrthographicCamera(
      -d * aspect,
      d * aspect,
      d,
      -d,
      0.1,
      50
    );
    camera.position.z = 5;

    // Renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    currentMount.appendChild(renderer.domElement);

    // Initial Geometry and Material
    const material = new THREE.MeshBasicMaterial({
      color: geometry.color,
      wireframe: true,
    });
    const mesh = new THREE.Mesh(geometry.geometry, material);
    meshRef.current = mesh;
    scene.add(mesh);

    // Set initial scale
    const setMeshScale = () => {
      if (meshRef.current) {
        const scaleFactor = Math.min(
          Math.max(currentMount.clientWidth / 500, minSize),
          maxSize
        );
        meshRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);
      }
    };
    setMeshScale();

    // Animation
    let previousTime = 0;

    const animate = (time) => {
      requestAnimationFrame(animate);

      const deltaTime = time - previousTime;
      previousTime = time;

      mesh.rotation.x += 0.0001 * deltaTime;
      mesh.rotation.y += 0.0001 * deltaTime;

      renderer.render(scene, camera);
    };
    animate(0);

    // Handle Window Resize
    const handleResize = () => {
      aspect = currentMount.clientWidth / currentMount.clientHeight;
      d = 2000 / currentMount.clientWidth;

      camera.left = -d * aspect;
      camera.right = d * aspect;
      camera.top = d;
      camera.bottom = -d;
      camera.updateProjectionMatrix();

      renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
      setMeshScale(); // Update scale based on window size
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      currentMount.removeChild(renderer.domElement);
    };
  }, [geometry, minSize, maxSize]);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.geometry.dispose();
      meshRef.current.geometry = geometry.geometry;
      meshRef.current.material.color.set(geometry.color);
    }
  }, [geometry]);

  return <div ref={mountRef} style={{ width: "100%", height: "100%" }} />;
};

export default ThreeScene;
