import React, { useState, useEffect } from "react";
import THREE from "../utils/Three.js";
import ThreeScene from "../components/Canvas";
import huetopiaCoverImage from "../img/Hutopia_Cover_Square_Image.png";
import { Squircle } from "@squircle-js/react";

function Home() {
  const [currentGeometryIndex, setCurrentGeometryIndex] = useState(0);

  const geometries = [
    { geometry: new THREE.BoxGeometry(), color: 0xff6060 },
    { geometry: new THREE.SphereGeometry(1, 8, 8), color: 0x60ff60 },
    { geometry: new THREE.PlaneGeometry(2, 2), color: 0x6060ff },
    { geometry: new THREE.CylinderGeometry(1, 1, 2, 8), color: 0x0fff00 },
    { geometry: new THREE.ConeGeometry(1, 2, 8), color: 0x8888ff },
    { geometry: new THREE.TorusGeometry(1, 0.4, 8, 8), color: 0x00ffff },
    { geometry: new THREE.TorusKnotGeometry(1, 0.4, 16, 16), color: 0xffdd88 },
    { geometry: new THREE.CircleGeometry(1, 8), color: 0xbb88ff },
    { geometry: new THREE.DodecahedronGeometry(1), color: 0x88ddff },
    { geometry: new THREE.IcosahedronGeometry(1), color: 0xff88dd },
    { geometry: new THREE.OctahedronGeometry(1), color: 0xaaff44 },
    { geometry: new THREE.TetrahedronGeometry(1), color: 0xff6666 },
    { geometry: new THREE.RingGeometry(0.5, 1, 8), color: 0x4444ff },
    // {
    //   geometry: new THREE.LatheGeometry(
    //     [
    //       new THREE.Vector2(0, 0),
    //       new THREE.Vector2(1, 1),
    //       new THREE.Vector2(0.5, 1.5),
    //     ],
    //     8
    //   ),
    //   color: 0xff44ff,
    // },
  ];

  const handleClick = () => {
    setCurrentGeometryIndex((prevIndex) => (prevIndex + 1) % geometries.length);
  };

  useEffect(() => {
    // Randomize the index when the component mounts
    const randomIndex = Math.floor(Math.random() * geometries.length);
    setCurrentGeometryIndex(randomIndex);
  }, [geometries.length]); // Only run when the geometries array length changes

  return (
    <div className="relative w-full h-auto bg-white flex flex-col items-center justify-start">
      <div className="relative w-full flex flex-col items-center justify-center">
        <div className="relative w-full h-[50vh] md:h-[70vh] lg:h-[calc(100vh-4rem)]">
          <ThreeScene
            geometry={geometries[currentGeometryIndex]}
            minSize={0.5} // Minimum scale
            maxSize={1} // Maximum scale
          />{" "}
          <span
            style={{ cursor: "pointer", whiteSpace: "nowrap" }}
            className="absolute inset-0 flex items-center justify-center text-lg md:text-2xl font-dot-gothic-16 text-black select-none z-10"
            onClick={handleClick}
          >
            Craft&nbsp;Experience
            <br />
            Build&nbsp;Connection
          </span>
        </div>
      </div>

      <div className="relative w-full bg-white flex items-center justify-center h-[30vh] md:h-[30vh] lg:h-[30vh] mt-10 mb-10">
        <a
          href="https://www.meta.com/experiences/26230755453235481/"
          target="_blank"
          rel="noopener noreferrer"
          className="block overflow-hidden bg-white transition-transform transform hover:scale-110"
          style={{
            width: "60vw", // Larger square size relative to viewport width
            aspectRatio: "1",
            minWidth: "150px", // Larger minimum size
            maxWidth: "300px", // Larger maximum size
          }}
        >
          <Squircle
            cornerRadius={100} // Adjust the radius of the corners
            cornerSmoothing={100} // Smoothness of the corners
            className="w-full h-full overflow-hidden"
            style={{ backgroundColor: "#f9f9f9" }} // Optional background for better visibility
          >
            <img
              src={huetopiaCoverImage}
              alt="Huetopia"
              className="w-full h-full object-cover select-none pointer-events-none"
              draggable="false" // Prevent dragging
              onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
            />
          </Squircle>
        </a>
      </div>
    </div>
  );
}

export default Home;
